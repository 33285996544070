//Plugins

//@import "~fancybox/dist/css/jquery.fancybox.css";
@import "../../node_modules/select2/dist/css/select2.min.css";
@import "../../node_modules/@fortawesome/fontawesome-free/css/all.css";
@import "../../node_modules/@chenfengyuan/datepicker/dist/datepicker.min.css";
@import "../../node_modules/bootstrap-table/dist/bootstrap-table.min.css";
@import "../../node_modules/leaflet/dist/leaflet.css";
@import "../../node_modules/leaflet.markercluster/dist/MarkerCluster.css";
@import "../../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css";
// bootstrap 4
@import "../../node_modules/bootstrap/scss/functions";
@import "variables";
@import "../../node_modules/bootstrap/scss/mixins";
//@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/jumbotron";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/print";
body{
  background-color: #f0f4fa;
}
#wrapper{
  position:relative;
  overflow:hidden;
}
.blue{
  background-color: #004e8d;
}
bg-white{
  background: #fff;
}

.leaflet-container {
  height: 400px!important;
  width: 100%;
}

header {
  overflow: hidden;
  background-color: #f1f1f1;
}

.logo {
  margin-top: -4px;
  margin-bottom: -4px;
  margin-right: 7.300em;
  margin-left: 12.650em;
}
#navbarResponsive{
  .nav-link {
    font-family: 'Source Sans Pro';
    font-size: 13px;
    line-height: 35px;
    text-transform: uppercase;
    white-space: nowrap;
    &.nav-bigger{
      font-size:17px;
    }
    &.active,&:hover{
      font-weight: bold !important;
    }
  }
  @media (min-width:768px){
    .navbar-main{
      .nav-item {
        border-right: 1px solid #e2dede;
        &:last-child{
          border: none;
        }
      }
    }
  }
  /** social network icon **/

  .nav-social{
    .nav-link{
      padding:0.5rem;
      width:51px;
      text-align:center;
      margin-right:15px;
      cursor:pointer;
      font-size: 20px;
      .fab{
        color:#fff;
      }
    }
    @media (max-width:768px){
      display:inline-block;
      .nav-item{
        display:inline-block;
      }
    }
  }
}
.facebook {
  background: #3b5998;
}
.twitter{
  background: #4099FF;
}
.linkedin{
  background: #127bb6;
}
.navbar-dark .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.multimedia {
  width: 1rem;
  height: 1rem;
  margin-right: 16.625rem;
  top: 1.875rem;
}

ul p{
  margin-bottom: 10px;
}

/*** main banner ***/

.banner{
  min-height: 350px;
  background: #be1522;
  background-repeat: no-repeat;
  background-size: 1526px;
  background-position: 0 -60px;
  margin-bottom: 15px;
}

.banner-title .intro{
  margin-top: 37px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.1;
  z-index: 1;
  margin-bottom:20px;
  display:block;
  @media (min-width:768px){
    line-height: 19px;
  }
}

.banner-title h1{
  color: #ffffff;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  z-index: 1;
  font-size: 30px;
  @media (min-width:850px){
    font-size: 30px;
    margin-bottom: 25px;
  }
  @media (min-width:1200px){
    font-size: 40px;
    margin-bottom: 25px;
  }
}

.banner-title .para{
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  max-width: 800px;
  width:100%;
  line-height: 1.5;
}
.search-intro{
  font-size:12px;
  line-height:1.2;
}

#mainsearch{
  margin: 0 auto 50px;
}
/*** statistic main container ***/
@media (min-width:1400px){
  .container{
    max-width:1340px;
  }
}
.nav-tabs{
  display: flex;
}

.main-container{
  margin-bottom: 35px;
  @media (min-width:768px){
    margin-bottom: 85px;
  }
}
.main-container .nav-item{
  border-color:#f2f5f9;
  border-bottom:1px solid #f2f5f9;
  width:100%;
  @media (min-width:768px){
    width:auto;
  }
}
.main-container .nav-link{
  color: #000000;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  font-size:13px;
  display: block;
  vertical-align: middle;
  text-align: center;
  padding:15px;
  background-color:#fff;
  @media (min-width:768px){
    display: table-cell;
    height: 92px;
  }
}

.main-container .nav-link.active,.main-container .nav-link:hover{
  color: #005091;
  background-color: #e1e9f6;
  border-bottom: 2px solid #be1522;
}
label{
  opacity: 0.8;
  color: #1763ff;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.input-group-append{
  cursor:pointer;
}
.form-label{
  color: #1763ff;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  text-transform: uppercase;
}
.custom-control-label{
  color:#000;
  padding-top: 3px;
  text-transform: lowercase;
}
#label-round{
  opacity: 0.8 !important;
  color: #000000;
  font-family: "Myriadpro";
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 2px;
}

.btn-submit{
  margin-top:15px;
}
.select2-choices {
  min-height: 150px;
  max-height: 150px;
  overflow-y: auto;
}
.select2-container .select2-selection--single{
  height:38px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered{
  line-height:38px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow{
  height:36px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected]{
  background-color: $primary;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice{
  font-size:12px;
}

.plus-citere{
  font-size: 13px;
  font-family: "Myriad Pro";
  color: rgba(0, 0, 0, 0.8);
  line-height: 1.538;
}
.toggle.ios{
  height:20px !important;
  width:40px !important;
  line-height:20px;
}
.toggle.ios, .toggle-on.ios, .toggle-off.ios { border-radius: 10px; }
.toggle.ios .toggle-handle {
  border-radius: 10px;
  padding-top:0;
  padding-bottom:0;
  border-width: 0 1px;
  line-height:1;
}


.required:after{
  content:' *';
  color:red;
}
/** special triangle **/

.red {
  background-color: #be1522;
}
.orange{
  background-color: #ff3c00;
}
.up-collapse{
  color:#a2c1ff;
  float: right;
  font-size:20px;
  cursor:pointer;
  appearance:none;
  -webkit-appearance: none;
  -moz-appearance:    none;
  border:0 none;
  padding:0;
  background:none;
  height: 50px;
  line-height: 50px;
  &:hover{
    color:#1763ff;
  }
  &:focus{
    outline: 0 none;
  }
  .fa-stack-2x{
    top:0;
    font-size: 50px;
  }
  .fa-stack-1x{
    top:0;
    font-size:25px;
  }
  &:disabled{
    cursor: wait;
    .fa-stack-1x{
      animation: fa-spin 2s infinite linear;
      &:before{
        content:"\F110";
      }
    }
  }
}
.up-collapse[aria-expanded="true"] .fa-angle-down{
  transform: rotate(-180deg);
}
.triangle{
  position: relative;
  padding: 10px 15px 10px 40px;
  box-sizing: border-box;
}

.triangle:after{
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 0em;
  left: 2%;
  box-sizing: border-box;
  border: .6em solid black;
  border-color: transparent transparent #f0f4fa #f0f4fa;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-shadow: 0 rgba(0, 0, 0, 0.4);
}

.header-triangle{
  color: #ffffff;
  font-family: "Montserrat";
  font-weight: 400;
  text-transform: uppercase;
  font-weight: 600;
  @media (min-width:768px){
    line-height: 32px;
    font-size: 1.5em;
  }
}


.main_engine{
  margin-bottom: 50px;
  padding: 15px;
  @media (min-width:768px){
    padding: 50px;
  }
}

/* style general des blocks */
.blocks{
  margin-bottom:40px;
  //max-height:500px;
  &.red-block{
    border-bottom:1px solid #be1522;
  }
  &.blue-block{
    border-bottom:1px solid #004e8d;
  }
  &.orange-block{
    border-bottom:1px solid #ff3c00;
  }
  .collapse{
    &.show:not(.societies-map){
      padding: 15px;
    }
    &:not(.societies-map){
      max-height:751px;
      overflow-y:auto;
      overflow-x:hidden;

    }
  }
  .form-control,.export .btn{
    font-size:12px;
  }
  .pagination-detail{display:none}
  .table{
    font-size:12px;
    border: 1px solid #dee2e6;
    tr{
      td{
        &:first-child{
          text-transform:lowercase;
          &:first-letter{
            text-transform:uppercase;
          }
        }
        &:last-child, &:nth-child(2){
          text-align:right;
        }
      }
    }
  }

}
/* laoders */

.dataloading{
  display:block;
  font-size:16px;
  .fa{
    margin-right:10px;
  }
}
.fixed-table-loading{
  .dataloading{
    border: 1px solid #dee2e6;
    border-top:0 none;
    padding: .75rem;
  }
}
/* map */
.societies-map{
  position:relative;
  &.collapseloaded{
    .maploader{
      display:none;
    }
  }
}
.maptab{
  height:750px !important;
}
.maploader{
  position:absolute;
  top:15px;
  right:15px;
  z-index:999999;
}

@media screen and (max-width: 768px) {
  .logo {
    margin-right: 7.3em;
    margin-left: 4.65em;
  }
}

@media screen and (max-width: 360px) {
  .logo {
    margin-right: 7em;
    margin-left: 4em;
  }
  .navbar-nav {
    margin-left: 28px;
  }
}

@media screen and (max-width: 532px) {
  .logo {
    margin-right: 5em;
    margin-left: 4em;
  }
  .navbar-nav {
    margin-left: 28px;
  }
}

@media (min-width: 532px)and (max-width: 766px) {
  .navbar-nav {
    margin-left: 28px;
  }
  .d-sm-flex {
    display: initial !important;
  }
  .logo {
    margin-right: 7.3em;
    margin-left: 4.65em;
  }
}

@media (min-width: 766px)and (max-width: 900px) {
  .logo {
    margin-right: 5.3em;
    margin-left: 6.65em;
  }
}

.footer{
  min-height:130px;
  background-color:#005091;
  padding-top:20px;
  padding-bottom:20px;
  ul{
    margin:0;
    @media (max-width:768px){
      li,a{
        display:block !important;
      }
    }
  }
  a{
    color:#fff;
    &:hover{
      color:#000;
    }
  }

  .menu-footer{
    margin-top: 35px;
  }
}

$ToTopDim: 60px;
.totop {
  //border:1px solid #000;
  color: #fff;
  display:none;
  bottom:0;
  background-color: #be1522;
  /* cookies bar */
  bottom: 25px;
  height: 40px;
  position: fixed;
  right: 0;
  width: 40px;
  z-index: 999;
  text-decoration:none !important;
  text-align:center;
  &:hover{
    color:#fff;
  }
  i{
    display: block;
    font-size: 2em;
    line-height: 40px;
    margin: 0 auto;
  }
  @include media-breakpoint-up(md) {
    height: $ToTopDim;
    right: 50px;
    width: $ToTopDim;
    bottom:50px;
    i{
      font-size: 3em;
      line-height: $ToTopDim;
    }
  }
}

/* analyses */
.btn-red{
  color: #fff;
  background-color: #be1522;
  border-color: #be1522;
  border-radius:25px;
  height:50px;
  padding:0 27px;
  line-height:50px;
  &:hover{
    color: #be1522;
    background-color: #fff;
    border-color: #be1522;
  }
}
.metas{
  font-size: 14px;
  color: rgb(180, 180, 180);
  margin-bottom:10px;
  a{
    color: rgb(180, 180, 180);
    text-transform:uppercase;
    text-decoration:underline;
    &:hover{
      color:#000;
    }
  }
}

.card-columns {
  @media(min-width:768px) {
    column-count: 2;
  }
}

.pl-40{
  padding-left: 40px;
}

.box{
  margin-bottom:50px !important;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 14.55px 0.45px rgba(0, 0, 0, 0.1);
  border-radius:0;
  border:0 none;
  &.rte{
    padding:15px;
    position:relative;
    @media (min-width:768px){
      padding:50px;
      min-height:240px;
    }
    h1,h2,h3,h4{
      color:#be1522;
    }
    h3{
      color: #005091;
      font-size : 20px
    }
    p:not(.metas){
      font-size:14px;
      color:#000;
    }
  }
  .content{
    padding:25px;
    h2{
      font-size: 17px;
      color: #005090;
      text-transform: uppercase;
      margin-bottom: 15px
    }
    p{
      margin-bottom: 10px;
      font-size: 15px;
      line-height: 16px;
      font-style: italic;
    }
    .btn-red{
      margin:0 auto;
      display:block;
      max-width:200px;
    }
  }
}
.share{
  @media (min-width:768px){
    position:absolute;
    right:-60px;
    top:0;
  }
  a{
    width:60px;
    height:60px;
    text-align:center;
    line-height:60px;
    display:inline-block;
    color:#fff;
    @media (max-width:767px){
      float:left;
    }
    @media (min-width:768px){
      display:block;
    }
    .fa{
      color:#fff;
      font-size:30px;
    }
  }
}


// breadcrumbs

.breadcrumb
{
  font-size: 0.8em;
  background: none;
  .active {
    color: #be1522;
    font-weight: bold;
  }
}
.departh2{
  margin-bottom:1rem;
}
#departements
{
  margin-bottom: 40px;
  list-style-type: none;
  li {
    display: inline;
    padding: 10px;

    a {
      color: #005091;
      &.active{
        color: #BE1522 !important;
        font-weight: bold;
      }
    }
  }
}
.departements_list{
  padding:0;
  list-style-type: none;
  li{
    display: inline-block;
    width:100%;
    a{
      display: block;
          padding: 10px 80px 10px 15px;
          background-color: #005091;
          margin-bottom: 15px;
          color: #fff;
          position: relative;
      strong{
        font-weight: 400;
            font-size: 20px;
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translate(0, -50%);
      }
    }
    &.last{
      a{
        padding-left:15px;
        background-color: #be1522;
      }
    }
  }

}

// reviews
.reviews{
  .listyears{
    padding-left:20px;
    a.active{
      color: #BE1522 !important;
      font-weight: bold;
    }
  }
  .box .content .btn-red{
    max-width:220px;
  }
}
